.diagnostic-legend {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  .diagnostic-legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-right: 1rem;
  }
  .diagnostic-legend-item-color {
    display: inline-flex;
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.3rem;
  }
  .green {
      background-color: #b0f2c2;
      color: #b0f2c2;
    }
  .red {
    background-color: #fcb7af;
    color: #fcb7af;
  }
  .gray {
    background-color: gray;
    color: gray;
  }
  .diagnostic-legend-item-text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    // color: gray;
  }
}
.green-line {
  background-color: #b0f2c2;
}
.red-line {
  background-color: #fcb7af;
}

.diagnostic-client-flex {
  display: flex;
  margin-top: 10px;
}