.bread-link {
  text-decoration: none;
  color: #6ea13f;
}

.bread-link:hover {
  color: #6ea13f;
}

.bread-final {
  color: #6ea13f;
  font-weight: bold;
}