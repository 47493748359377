.kam-meeting {
  margin-left: 20vw;
  margin-right: 20vw;
  // height: 250px;
  margin-bottom: 50px;
  margin-top: 20px;
}
.multi-select-meeting {
  margin-bottom: 1rem;
}

.meeting-detail {
  color: black;
  font-size: 1.5rem;
  text-align: left;
  margin-top: 20px;
  .paragraph {
    white-space: pre-line;
    margin-top: 20px;
    justify-content: center;
    text-align: justify;
    font-size: 0.75rem;
    }
  .small-paragraph {
    white-space: pre-line;
    margin-top: 20px;
    justify-content: center;
    text-align: justify;
    font-size: 1rem;
    }
}