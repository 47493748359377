.button-pdf {
  background-color: #f8f9fd;
  margin-left: 20vw;
  margin-right: 20vw;
}

.stepper {
  align-items: center;
  display: flex;
  justify-content: center;
}
