footer{
  position: absolute;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100vw;
  padding-top: 20px;
  margin-top: 50px;
  padding-bottom: 10px;
  background-color: #363839;
  color: #b0b0b0;
  text-align: center;
}