.big-card {
  margin: 0 auto;
  width: 80%;
  background-color: white;
  padding: 40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  flex-wrap: wrap;
  input {
    width: 100%;
  }
}

.flex-register {
  display: grid;
  grid-template-columns: 50% 50%;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
}

.show-password {
  cursor: pointer;
}