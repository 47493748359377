.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: white;
  width: 20vw;
  height: 100vh;
  // display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -20%;
  transition: 850ms;
  box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
  bottom: 0;
  z-index: 999;
  overflow-y: auto;
  ul {
    padding-left: 0;
  }
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  // justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #6ca437;
}

.nav-menu-items {
  width: 100%;
  a.active {
    font-weight: bold;
  }
}

.navbar-toggle {
  background-color: white;
  width: 100%;
  height: 80px;
  display: flex;
  // justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.dropdown-link {
  background: rgb(238, 236, 236);
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 18px;
  width: 100%;
 
  &:hover {
    background: #6ea13f;
    color: black;
  }
}

.dropdown-span {
  margin-left: 16px;
}
.dropdown-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .active {
    font-size: bold;
  }
}