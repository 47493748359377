body {
  background-color: #f8f9fd;
  position: relative;
  padding-bottom: 3em;
  min-height: 100vh;
}
.page-container {
  padding-bottom: 4em;
  left: 0;
  transition: 850ms;
}

.page-container.active {
  margin-left: 20vw;
  transition: 350ms;

}