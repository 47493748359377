.user-table {
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  overflow-y: auto;
}

.th-lg {
  width: 8rem;
}

.th-lg-percent {
  width: 25%;
}
