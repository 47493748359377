.app {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  background-color: #f8f9fd;
  margin-top: 10px;
}

.login-group {
  height: 25px;
  width: 80%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.submit-login {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #01d28e;
  border: 1px solid #01d28e;
  color: #fff;
  padding: 10px 20px;
}

.submit-login:hover {
  background: #6cf0c2;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  border-radius: 5px;
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: auto;
  width: 400px;
  margin-top: 0px;
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.success {
  color: green;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: 20px;
  margin: 10px;
}
.input-icon {
  position: absolute;
  // background-color: rgb(169, 162, 162);
  // border: 1px solid rgba(0, 0, 0, 0.2);
  margin-left: -26px;
  padding-right: 4px;
  padding-left: 4px;
  height: 25px;
  cursor: pointer;
}

.recovery-password {
  display: flex;
  margin: 10px;
  flex-direction: column;
}
