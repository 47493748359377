h1 {
  text-align: center;
}

.search {
  margin-left: 60%;
  margin-right: 0;
  right: 0;
  width: 40%;
  display: flex;
  float: left;
  p {
    margin: 0;
    margin-right: 10px;
  }
  input {
    width: 60%;
  }
}