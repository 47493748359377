.cardInicio {
  position: relative;
  width: 300px;
  height: 350px;
  margin: 20px;
  .face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    overflow: hidden;
    transition: 1s;
  }
  .front {
    transform: perspective(600px) rotateY(0deg);
    box-shadow: 0 5px 10px #000;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    h3 {
      margin-bottom: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 45px;
      line-height: 45px;
      color: #fff;
      background: rgba(0,0,0,0.4);
      text-align: center;
    }
  }
  .back {
    transform: perspective(600px) rotateY(180deg);
    background: rgba(108,164,55,0.9);
    // background: rgb(3, 35, 54);
    padding: 15px;
    color: #f3f3f3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    box-shadow: 0 5px 10px #000;
    cursor: pointer;
    .link {
      border-top: solid 1px #f3f3f3;
      height: 50px;
      line-height: 50px;
      p {
        color: #f3f3f3;
        text-decoration: none;
        &:hover {
          color: #f3f3f3;
        }
      }
    }
    h3 {
      font-size: 30px;
      margin-top: 20px;
      letter-spacing: 2px;
    }
    p {
      letter-spacing: 1px;
    }
  }
  &:hover .front {
    transform: perspective(600px) rotateY(180deg);
  }
  &:hover .back {
    transform: perspective(600px) rotateY(360deg);
  }
}
.card-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}