%flex-display {
  display: flex;
}
.star-rating {
  @extend %flex-display;
  align-items: center;
  // font-size: 3em;
  justify-content: center;
}
.back-stars {
  @extend %flex-display;
  color: grey;
  position: relative;
  // text-shadow: 4px 4px 10px #843a3a;
}
.front-stars {
  @extend %flex-display;
  color: #FFBC0B;
  overflow: hidden;
  position: absolute;
  text-shadow: 2px 2px 5px #d29b09;
  top: 0;
  // transition: all .5s
}

.star-hover {
  position: relative; 
  display: inline-block;
}

.star-half-1 {
  position: absolute;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: 2 
}

.star-half-2 {
  position: absolute;
  left: 50%;
  width: 50%;
  height: 100%;
  z-index: 2 
}