.add-job-previous-experience {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  
  button {
    margin-right: 0;
    margin-left: auto;
    right: 0;
    justify-content: flex-end;
  }
}
