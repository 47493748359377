/* * {
  box-sizing: border-box;
}
body {
  margin: 0;
}

nav{
  display: flex;
  background-color: rgb(188, 181, 236);
  color: black;
  justify-content: space-between;
  align-items: center;
  gap: 2rem ;
  padding: 0 1rem;
}

nav ul{
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}
nav li{
  flex: 1;
  padding: 20px;
}

nav li:hover{
  background-color: #777;
}
nav a {
  color: inherit;
  text-decoration: none;
}

.title{
  display: flex;
  font-size: 2rem;
} */

.nav-class {
  background-color: white;
  margin-bottom: 50px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
  left: 0;
  right: 0;
  transition: 850ms;
}
.logo-impacto-legal {
  height: 30px;
}

.nav-class.active {
  margin-left: 20vw;
  transition: 350ms;
  right: 0;
}

.breadcrumbs {
  overflow-x: auto;
  white-space: nowrap;
  /* max-width: 40vw; */
  margin-left: 5px !important;
}

.notification-dropdown {
  position: relative;
  display: inline-block;
  appearance: none;
  padding: auto;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  width: 42px;
  height: 42px;
  
}
.notification-dropdown::after {
  content: none;
}
.notification-dropdown .dropdown-toggle::after {
  content: none;
}
.notification-dropdown:hover {
  cursor: pointer;
  background-color: aliceblue;
}
.notification-dropdown svg {
  font-size: 1.5rem;
  color: #000;
}
.notification-dropdown span {
  margin: 0;
}
.notification-dropdown .dropdown-menu {
  min-width: 300px;
  max-width: 400px;
  max-height: 4000px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  left: auto;
  right: 0;
}
.notification-dropdown .dropdown-item {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}